<template>
  <div class="ball d-flex align-items-center justify-content-center" :class="shadowClass" :style="bgStyle">
        <div class="text-ball">
          <p class="m-0">{{text}}</p>
        </div>
      
  </div>
</template>

<script>
export default {
    props: ['text', 'color', 'shadow', 'zIndex'],
    data() {
        return {
            bgStyle: {},
            shadowClass: ""
        }
    },
    created() {
        this.bgStyle['background-color'] = this.color;
        this.bgStyle['z-index'] = this.zIndex;

        if(this.shadow == 'true'){
            this.shadowClass = 'shadow'
        } else {
             this.shadowClass = ''
        }
    }
}
</script>

<style>
    .text-ball {
        font-size: 16px;
        padding: 41px;
        box-sizing: border-box;
        font-weight: bold;
    }
    .ball {
        width: 12vw;
        height: 12vw;
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 7vw;
        box-sizing: border-box;
    }
    .text {
        font-size: 14px;
        padding: 41px;
        box-sizing: border-box;
    }
    .shadow {
        box-shadow: 0px 40px 40px #aaaaaa60;
    }
@media screen and (max-width: 600px) {
    .text-ball {
        font-size: .7rem;
        font-weight: bold;
        line-height: 1.17;
        text-align: center;
        color: #505050;
        padding: 1rem;
    }
    .ball {
        width: 110px;
        height: 110px;
        margin-left: 0px;
        margin-right: -13px;
        border-radius: 110px;
    }
}
</style>

