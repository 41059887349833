<template>
    <div class="ProcessoDeAvaliacao">
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 stripe-evaluation text-center" title="Nome comum: Capitão-do-mato; Crédito: Petr Ganaj">
                    <span class="tit1">Processo</span><br />
                    <span class="tit2">de Avaliação</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="circulos text-ball row d-flex align-items-center justify-content-center">
                <Ball text="Compilação de Dados" color="#FAFFE9" shadow="false" zIndex="0" class="mtop-0"/>
                <Ball text="Consulta à Sociedade Brasileira" color="#F7FFDE" shadow="true" zIndex="1" class="mtop-1"/>
                <Ball text="Oficina de Avaliação" color="#F3FFC8" shadow="false" zIndex="0" class="mtop-0"/>
                <Ball text="Oficina de Validação" color="#EBFBB4" shadow="true" zIndex="1" class="mtop-1"/>
                <Ball text="Publicação" color="#E2F5A1" shadow="false" zIndex="0" class="mtop-0"/>
            </div>
            <div class="row text-left">
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        O Instituto Chico Mendes conduz o processo desde 2009, atualmente normatizado pela Instrução Normativa <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/legislacao/instrucoes-normativas/arquivos/in_9_de_11_de_agosto_de_2020.pdf" target="_blank">ICMBio n° 09/2020</a>, que define as suas diretrizes gerais, a metodologia utilizada, os atores envolvidos e suas funções, as etapas e os prazos. O processo é executado pelos Centros Nacionais de Pesquisa e Conservação em etapas e conta com a participação ativa da sociedade e da comunidade científica.
                        <br><br>
                        Inicialmente é feita uma compilação e organização de informações e registros de ocorrência em fichas individuais para cada espécie. Em seguida, são feitas consultas, ampla à sociedade brasileira e direta aos especialistas da comunidade científica, para revisão das informações.
                    </p>
                </div>
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        Depois de incorporadas as contribuições da etapa de consulta, o risco de extinção das espécies é avaliado seguindo o método de categorias e critérios da União Internacional para a Conservação da Natureza (UICN) em oficinas com a participação de especialistas da comunidade científica. Posteriormente, é realizada uma validação dos resultados para checagem da consistência da aplicação do método e das informações que subsidiam a avaliação.
                        <br><br>
                        Após a revisão das fichas, os resultados são publicados e subsidiam a implementação de políticas públicas como a Lista Nacional Oficial de Espécies da Fauna Brasileira Ameaçadas de Extinção, Planos de Redução de Impactos sobre a Biodiversidade (<a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade/plano-de-reducao-de-impactos-sobre-a-biodiversidade" target="_blank">PRIM</a>), Planos de Ação Nacional para Conservação de Espécies Ameaçadas de Extinção (<a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade/pan" target="_blank">PAN</a>), Mapeamento de Áreas Prioritárias, entre outros.
                    </p>
                    <p class="text-justify">
                        Participe! Para saber quais são as consultas abertas, <a href="/salve-consulta/" target="_blank">clique aqui</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Ball from '@/web/components/EvaluationProcessBall.vue'

export default {
    components: {
        Ball
    },
    data() {
        return {

        }
    },
    created() {

    }
}
</script>

<style scoped>
    .text-justify{
        text-align: justify;
    }
    .mtop-0{
        margin-top: 20px;
    }
    .mtop-1{
        margin-top: -40px;
    }
    .circulos {
        margin-top: -6rem;
    }
    .stripe-evaluation{
        background-image: url(/img/animals/borboleta-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
    .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
        border-top: 3px solid black;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
    }
@media screen and (max-width: 600px) {
    .mtop-0{
        margin-top: 0px;
    }
    .mtop-1{
        margin-top: 0px;
    }
    .circulos {
        margin-top: -1.5rem;
    }
    .stripe-evaluation{
        height: 115px;
        padding-top: 40px;
    }
    .tit1 {
        font-size: 1.3rem;
        padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
}
</style>